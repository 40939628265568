import { Tolgee, DevTools, FormatSimple, BackendFetch, type TolgeeStaticData } from '@tolgee/vue'
import { FormatIcu } from '@tolgee/format-icu'
import editorTranslations from '@/i18n/en.editor.json'
import en from '@/i18n/en.json'
import de from '@/i18n/de.json'
import fr from '@/i18n/fr.json'
import type { LanguageType } from '@/api/interfaces'
import { LANGUAGES } from '@/api/interfaces'

export const LS_LANGUAGE_KEY = 'user-locale'

export const languages: Record<LanguageType, string> = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
}

const editorStaticData: TolgeeStaticData = {
  'en:local': editorTranslations,
}

const staticData: TolgeeStaticData = {
  ...editorStaticData,
  en,
  de,
  fr,
}

const tolgee = Tolgee().use(DevTools()).use(FormatSimple()).use(FormatIcu())

// use the CDN for translations in deploy preview environments
export const isDeployPreviewEnvironment = window.location.hostname.startsWith('deploy-preview')
if (isDeployPreviewEnvironment) {
  tolgee.use(BackendFetch({ prefix: import.meta.env.VITE_TOLGEE_CDN }))
}

export default tolgee.init({
  availableLanguages: [...LANGUAGES],
  language: import.meta.env.VITE_DEFAULT_LOCALE,
  staticData: isDeployPreviewEnvironment ? editorStaticData : staticData,
})
